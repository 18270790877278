.content-wrapper{
    display:flex;
    flex:1;
    flex-direction:column;
    margin-top: 50px;
}

.content-wrapper.with-header-banner{
  margin-top: 80px;
}

.upload-wrapper{
    text-align: center;
    margin-top: 20px;
    color: #777777;

    .help-wrapper {
        text-align: left;
        max-width: 600px;

        .welcome {
            font-size: 16px;
        }
        .title {
            font-size: 18px;
            font-weight: bold;
            text-decoration: underline;
        }
        .help-text {
            font-size: 14px;
        }
    }

    .or-circle{
        width: 40px;
        background: #E9E8F2;
        box-shadow: none;
        border-radius: 20px;
        height: 40px;
        border-width: 1px;
        border-style: solid;
        border-color: #777777;
        z-index: 2;
        left: calc(50% - 20px);
    }
    .overlay{
        position: absolute;
        // top: 0px;
        // left: 0px;
        // padding: auto;
    }
    .or-text{
        padding: 15px 0;
        font-size: 10px;
    }
    .or-line{
        stroke: #777777;
        stroke-width: 1;
    }
    .or-line-box{
        z-index: 1;
        left: calc(50% - 200px);
    }
    .or-box{
        // padding: auto;
        margin-top: 30px;
        width: 100%;
        height: 40px;
        position: relative;
    }
    .class-textbox{
        // margin-top: 30px;
        // width: 300px;
        // left: calc(50% - 150px);
    }
    .progress-wrapper{
        margin-top: 30px;
    }

    .number-picker{
        width: 50px;
    }
}
