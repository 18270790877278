#review-recording-snippets{
    display: flex;
    flex-direction: column;
    margin: auto;
    .title{
        font-size: 25px;
        font-weight: bold;
        margin:auto;
        margin-bottom:15px;
        .purple{
            color: red;
        }
    }
    .speed{
        width: 50px;
        right:0;
    }
    .box{
        display: flex;
        flex-direction: column;
        background-color: white;
        border: solid black 1px;
        padding: 40px;
        margin:auto;
        width: auto;
        
        .instructions-div{
            display: flex;
            flex-direction: column;
            font-size: 16px;
            margin: 20px;
            width: 800px;
        }
        .audios-div{
            flex:1;
            display: flex;
            .audio{
                flex:1;
                flex-direction: column;
                display: flex;
                margin: 5px 15px;
                border-radius: 10px;
                background-color:rgba(236, 236, 236, 0.8);
                .title{
                    font-size: 18px;
                    margin:auto;
                    margin-top:15px;
                }
                audio{
                    width: 100%;
                }
            }
        }
        .buttons-div{
            display: flex;
            margin: 20px auto;
            margin-bottom: 30px;
            .label-buttons{
                display: flex;
                margin: auto 15px;
                background-color: white;
            }
        }  
    }
    
    .skip-submit{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 10px;
    }
}





