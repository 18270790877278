#review-recording-snippets{
    .skip-button {
        background-color: white;
        font-size: 18px;
        padding: 10px;
        margin: 10px;
        width:150px;
        
        &:hover {
            background-color: rgba($color: #cccccc, $alpha: 0.2);
        }
    }
}