/**
* Extend the react-native-web reset:
* https://github.com/necolas/react-native-web/blob/master/packages/react-native-web/src/exports/StyleSheet/initialRules.js
*/
html,
body,
#root {
    width: 100%;
    /* To smooth any scrolling behavior */
    -webkit-overflow-scrolling: touch;
    margin: 0px;
    padding: 0px;
    /* Allows content to fill the viewport and go beyond the bottom */
    min-height: 100%;
}


html {
    scroll-behavior: smooth;
    /* Prevent text size change on orientation change https://gist.github.com/tfausak/2222823#file-ios-8-web-app-html-L138 */
    -webkit-text-size-adjust: 100%;
    height: calc(100% + env(safe-area-inset-top));
}

body {
    display: flex;
    /* Allows you to scroll below the viewport; default value is visible */
    overflow-y: auto;
    overscroll-behavior-y: none;
    text-rendering: optimizeLegibility;
}  

#root {
    flex-shrink: 0;
    flex-basis: auto;
    flex-grow: 1;
    display: flex;
    flex: 1;
    font-size: 14px;
    line-height: 1.4285em;
    justify-content: center;
    background-color: whitesmoke;
    font-family: 'Nunito', sans-serif;
}
  
span, div {
    font-family: inherit;
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box; 
}

button {
    cursor: pointer;
}


.flex-column-wrapper{
    display: flex;
    flex-direction: column;
}

.shared-sticky-note-header-background {
    background: #F2E782 !important;
}

.shared-sticky-note-body-background {
    background: #FFF59B !important;
}

.private-sticky-note-header-background {
    background: #DFB7F4 !important;
}

.private-sticky-note-body-background {
    background: #E2C4F2 !important;
}

.tfx-color {
    color: #00BBFF !important;
}

.trophy-gold-color {
    color: #F3B954 !important;
}

.trophy-brown-color {
    color: #CB846D !important;
}

.tfx-accent-color {
    color: #07b05d !important;
}

.tfx-color-dark {
    color: #10A5E8 !important;
}

.admin-color {
    color: #E65838 !important;
}

.full-screen-error-message {
    display:flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items:center;
}

.admin-background-color {
    background: #E65838 !important;
    color: black !important;
}

.teachfx-background-color {
    background: #00BBFF !important;
}

.teacher-color, .teacher-color-on-hover:hover {
    color: #FF345E !important;
}
.student-color, .student-color-on-hover:hover {
    color: #00BBFF !important;
}
.multi-color, .multi-color-on-hover:hover {
    color: #0071C0 !important;
}
.silence-color, .silence-color-on-hover:hover {
    color: #00D97F !important;
}

.teacher-background-color {
    background: #FF345E !important;
}
.student-background-color {
    background: #00BBFF !important;
}
.multi-background-color {
    background: #0071C0 !important;
}
.silence-background-color {
    background: #00D97F !important;
}


progress[value] {
    // Remove default style
    // TODO: Add support for IE and Firefox
    // appearance: none;
    // -moz-appearance: none;
    -webkit-appearance: none;

    width: 320px;
    height: 10px;
}
progress[value]::-webkit-progress-bar {
    background-color: #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}
progress::-webkit-progress-value {
    background: #00BBFF;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.round-button:disabled{
    background: lightgray;
    cursor: default;
}

.round-button:hover:disabled {
    background: lightgray;
    color: white;
    cursor: default;
}

.round-button{
    width: 180px;
    border: 0;
    box-shadow: none;
    border-radius: 22px;
    height: 44px;
    margin-top: 35px;
    font-size: 12px;
    cursor: pointer;

    background: #00BBFF;
    color: white;
}
.round-button:hover {
    background: #10D0FF;
    color: white;
}


.clickable {
    cursor: pointer;
}


.flex{
    display:flex;
}
.flex-column{
    flex-direction: column;
}
.one-flex{
    flex:1
}
.center{
    justify-content: center;
    align-items:center;
}
.underline{
    text-decoration: underline;
}
.pointer{
    cursor: pointer;
}
.display-none{
    display: none !important;
}
.hidden{
    visibility: hidden !important;
}
.fa{
    font-size:10px
}
.sentry-error-embed{
    .form-submit .btn{
        background: #0BF !important;
        border: none !important;
    }
    .powered-by{
        opacity:0;
    }
}


.interaction-box{
    display:flex;
    flex-direction: column;
    width: 600px;
    height: 375px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    padding:30px;
    background-color:white;
}

.page-loader{
    display: flex;
    justify-content: center;
    margin-top: 150px;
}
