#review-recording-snippets{

    .label-button {
        background-color: white;
        font-size: 16px;
        padding: 10px;
        margin: 2px;
        
        &--teacher {
            &:hover {
                color:white;
                background-color: rgb(255, 52, 94);
            }
        }
        &--student {
            &:hover {
                background-color: rgb(0, 187, 255);
            }
        }
        &--group {
            &:hover {
                color:white;
                background-color: rgb(0, 113, 192);
            }
        }
        &--silence{
            &:hover {
                background-color: rgb(0, 217, 127);
            }
        }
        &--media{
            &:hover {
                background-color: rgb(213, 0, 217);
            }
        }
        &--null{
            &:hover {
                background-color: rgb(120, 120, 120);
            }
        }

        &--selected{
            &--teacher {
                background-color: rgba(255, 52, 94, 0.7);
            }
            &--student {
                background-color: rgba(0, 187, 255, 0.7);
            }
            &--group {
                background-color: rgba(0, 113, 192, 0.7);
            }
            &--media{
                background-color: rgb(213, 0, 217, 0.7);
            }
            &--silence{
                background-color: rgba(0, 217, 127, 0.7);
            }
            &--null{
                background-color: rgba(120, 120, 120, 0.7);
            }
        }
        
    }
}