#login-page {
	.text-field-wrapper{
		margin: 15px 0;
	}
	.submit-button{
		min-width: 130px;
		width: max-content;
		border: 0;
		box-shadow: none;
		border-radius: 15px;
		color: white;
		height: 28px;
		outline: none;
		cursor: pointer;
		margin-bottom: 10px;
		font-size: 12px;
		background: #0bf;
		padding: 0 20px;
	}
	.text-link{
		text-decoration: none;
		color: gray;
		font-size: 12px;
	}
	.text-link:hover{
		color:darkgrey;
		cursor: pointer;
	}
}