#splash-page{
    display: flex;
    flex-direction: column; 
    align-self:flex-start;
    margin-top: 150px;
    &__title{
        margin: auto;
        img{
            width:200px; 
            margin-bottom: 10px;
        }
    }
    &__loader{
        margin: auto;
        width: max-content
    }
}


