
#onboarding-box{
    background: white;
    border: 1px solid lightgrey;
    padding: 50px 75px 30px 75px;
    border-radius: 5px;
	align-self: center;
    .onboarding-title {
        width: max-content;
        margin: auto;
        margin-bottom: 20px;
		img {
				width: 229px;
		}
    }

    .onboarding-form-wrapper {
		text-align: center;
		margin-top: 10px;
		justify-content: center;
    }
}


#onboarding-steps{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .step{
		display: flex;
		cursor: default;
		&__number{
				display: flex;
				border-radius: 100%;
				margin: 5px;
				width: 16px;
				height: 16px;
				text-align: center;
				font-weight: bold;
				font-size: 8px;
				color: white;
				background: #0bf;
				align-items: center;
				justify-content: center;
		}
		&__label{
				display: flex;
				align-items: center;
				font-size: 12px;
		}
		&--inactive{
				.step__number{
						background: lightgray;
				}
				.step__label{
						color: gray;
				}
		}
    }
}